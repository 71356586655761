import React, { useCallback, useEffect, useReducer, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";

import EditActions from "./EditActions";
import EditPassword from "./EditPassword";
import PersonalInfo from "./PersonalInfo";
import {
  UserDetailsActionType,
  userDetailsInitialState,
  userDetailsReducer,
} from "./userDetailsReducer";
import { updateUserDetails, userDetails } from "../../api/control";
import {
  is401ClientError,
  is417ClientError,
  isHttpClientError,
} from "../../api/httpClient";
import { validateCredentials } from "../../api/validation";
import Box from "../../bp-ui/components/Box";
import Snackbar from "../../bp-ui/components/Snackrar";
import { UpdateUserDetailsBody } from "../../models/control";
import { UserDetails } from "../../models/user";
import {
  EMAIL_ALREADY_EXISTS_TRY_LOGIN_ERROR_MSG,
  EMAIL_NOT_VALID_ERROR_MSG,
  GENERIC_ERROR_MSG,
  UNAUTHORIZED_ERROR_MSG,
  USER_NOT_FOUND_ERROR_MSG,
} from "../../util/errorMessages";
import { USER_DETAILS_UPDATED_MSG } from "../../util/successMessages";
import AccessMetadata from "../metadata/AccessMetadata";

const EditUserDetails = () => {
  const { userId } = useParams();

  const [userDetailsData, setUserDetailsData] = useState<UserDetails>(Object);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarText] = useState("");

  const [state, dispatch] = useReducer(
    userDetailsReducer,
    userDetailsInitialState
  );

  const errorHandler = (error: unknown) => {
    if (isHttpClientError(error)) {
      if (is401ClientError(error as AxiosError)) {
        setError(UNAUTHORIZED_ERROR_MSG);
      } else {
        setError(USER_NOT_FOUND_ERROR_MSG);
      }
    }
  };

  const fetchData = useCallback(async (value: string) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const data = await userDetails(value);
      setUserDetailsData(data);
      dispatch({
        type: UserDetailsActionType.SET_USER_DETAILS_STATE,
        payload: data,
      });
    } catch (error) {
      errorHandler(error);
      setIsError(true);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData(userId!);
  }, [userId, fetchData]);

  const updateProfileInfoMutation = useMutation({
    mutationFn: async (data: UpdateUserDetailsBody) => {
      return updateUserDetails(userId!, data);
    },
    onSuccess: () => {
      fetchData(userId!);
      setShowSnackBar(true);
      setSnackBarText(USER_DETAILS_UPDATED_MSG);
    },
    onError: () => {
      setShowSnackBar(true);
      setSnackBarText(GENERIC_ERROR_MSG);
    },
  });

  const handleSnackbarClose = () => {
    setShowSnackBar(false);
  };

  const isEmailChanged = () => {
    return userDetailsData.email !== state.email;
  };

  const onUserDetailsFormSubmit = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    if (isEmailChanged()) {
      try {
        await validateCredentials("email", state.email);
      } catch (error) {
        let errorMessage = EMAIL_NOT_VALID_ERROR_MSG;

        if (isHttpClientError(error) && is417ClientError(error as AxiosError)) {
          errorMessage = EMAIL_ALREADY_EXISTS_TRY_LOGIN_ERROR_MSG;
        }

        dispatch({
          type: UserDetailsActionType.CHANGE_EMAIL_VALIDATION,
          payload: {
            isValid: false,
            errorMessage: errorMessage,
          },
        });
        return;
      }
    }
    const data: UpdateUserDetailsBody = {
      userId: state.id,
      isActive: state.isActive,
      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
    };
    updateProfileInfoMutation.mutate(data);
  };

  if (isLoading) {
    return <>Loading ....</>;
  }

  if (!userDetailsData || isError) {
    return <>{error}</>;
  }

  return (
    <Box>
      <Box
        id="user-details-form"
        component="form"
        onSubmit={onUserDetailsFormSubmit}
      >
        <PersonalInfo userDetailsState={state} dispatch={dispatch} />
        <AccessMetadata userDetailsState={state} />
        <EditPassword userId={userDetailsData.id} />
        <EditActions
          initialUserDetails={userDetailsData}
          userDetailsState={state}
          dispatch={dispatch}
        />
      </Box>
      <Snackbar
        open={showSnackBar}
        onClose={handleSnackbarClose}
        message={snackBarMessage}
      />
    </Box>
  );
};

export default EditUserDetails;
