import { Location } from "react-router-dom";

import { FormField } from "../../models/field";
import { getQueryParamValue } from "../util/url";

export interface FormState {
  username: FormField<string>;
  email: FormField<string>;
  password: FormField<string>;
  firstName: FormField<string>;
  lastName: FormField<string>;
  turnstileToken: FormField<string>;
}

export const initialFormState: FormState = {
  username: { value: "", error: false, errorMessage: "" },
  email: { value: "", error: false, errorMessage: "" },
  password: { value: "", error: false, errorMessage: "" },
  firstName: { value: "", error: false, errorMessage: "" },
  lastName: { value: "", error: false, errorMessage: "" },
  turnstileToken: { value: "", error: false, errorMessage: "" },
};

type FieldSetter = (fieldValue: FormField<string>) => void;

export type CreateSetterFunction = (fieldName: keyof FormState) => FieldSetter;

const getClientIdFromNextParamValue = (
  currentLocation: Location
): string | null => {
  const next = getQueryParamValue(currentLocation.search, "next");
  if (!next) return null;
  const absNextUrl = new URL(window.location.origin + next);
  return getQueryParamValue(absNextUrl.search, "client_id");
};

export const getClientIdParamValue = (
  currentLocation: Location
): string | null => {
  return (
    getQueryParamValue(currentLocation.search, "client_id") ||
    getClientIdFromNextParamValue(currentLocation)
  );
};
