import React from "react";

import EditUserDetails from "../components/edit/EditUserDetails";
import FullPageLayout from "../components/shared/FullPageLayout";

const EditPage = () => {
  return (
    <FullPageLayout>
      <EditUserDetails />
    </FullPageLayout>
  );
};

export default EditPage;
