import React, { useCallback } from "react";

import RegistrationFormStep from "./RegistrationFormStep";
import TermsOfServiceText from "./TermsOfServiceText";
import { CreateSetterFunction, FormState } from "./util";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { TurnstileSize } from "../../enums/turnstile/turnstileSize";
import { TurnstileStatus } from "../../enums/turnstile/turnstileStatus";
import { TurnstileTheme } from "../../enums/turnstile/turnstileTheme";
import BeatportTurnstile from "../account/BeatportTurnstile";
import { BrandFooter } from "../shared/BrandFooter";
import PrimaryButton from "../shared/PrimaryButton";

interface OwnProps {
  decrementStep: () => void;
  formState: FormState;
  isLoading: boolean;
  userEmail: string;
  createSetter: CreateSetterFunction;
}

const AccountCreation = ({
  decrementStep,
  isLoading,
  userEmail,
  createSetter,
}: OwnProps) => {
  const setTurnstileToken = useCallback(
    (value: string, error: boolean, errorMessage: string) => {
      createSetter("turnstileToken")({ value, error, errorMessage });
    },
    [createSetter]
  );

  const [status, setStatus] = React.useState<TurnstileStatus | null>(null);

  const setTurnstileStatus = useCallback(
    (status: TurnstileStatus) => {
      setStatus(status);
    },
    [status]
  );

  return (
    <Box className="fade-in">
      <RegistrationFormStep
        decrementStep={decrementStep}
        currentStep="3"
        numberOfSteps="3"
      />
      <Typography
        component="h1"
        variant="h3"
        sx={{
          lineHeight: "2.5rem",
          letterSpacing: "-0.013rem",
        }}
      >
        Almost done
      </Typography>
      <Typography
        component="div"
        sx={{
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "1.5rem",
          marginTop: "1rem",
          marginBottom: "2rem",
        }}
      >
        Look for an email confirmation that will be sent to{" "}
        {<strong className="sentry-mask">{userEmail}</strong>} to confirm your
        account.
      </Typography>
      <Box
        sx={{
          marginTop: "2rem",
        }}
      >
        <BeatportTurnstile
          options={{
            action: "registration",
            theme: TurnstileTheme.Auto,
            language: "auto",
            size: TurnstileSize.Normal,
            retryInterval: 5000,
          }}
          setTurnstileToken={setTurnstileToken}
          setTurnstileStatus={setTurnstileStatus}
        />
        <TermsOfServiceText />
        <PrimaryButton
          isLoading={isLoading}
          isDisabled={status == "solved" ? isLoading : true}
          type="submit"
          label="Create Account"
        />
      </Box>
      <BrandFooter />
    </Box>
  );
};

export default AccountCreation;
