import { useQuery } from "@tanstack/react-query";

import { userInfo } from "../api/auth";

export const currentUserQueryKey = "current-user-query";

export const useCurrentUserQuery = () =>
  useQuery({
    queryKey: [currentUserQueryKey],
    queryFn: userInfo,
    retry: 0,
    refetchOnWindowFocus: false,
  });
