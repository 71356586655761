import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import AccountScreenEnum from "./account-screen-enum";
import AccountSettingsField from "./AccountSettingsField";
import { logout } from "../../api/auth";
import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import WarningIcon from "../../bp-ui/icons/WarningIcon";
import { currentUserQueryKey } from "../../queries/useCurrentUser";
import Pages from "../../util/pages";
import useAuthCurrentUserQuery from "../../util/useAuthCurrentUserQuery";
import Unauthorized from "../shared/Unauthorized";
import { mobileScreenSx } from "../util/screen";
import { getSharedQueryParamsString } from "../util/url";

const getEmailLabelText = (isVerified: boolean): string => {
  if (isVerified) return "Email settings";
  return "Email settings (unverified)";
};

interface OwnProps {
  setEditScreen: (value: AccountScreenEnum) => void;
}

const AccountInfo = ({ setEditScreen }: OwnProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const currentUser = useAuthCurrentUserQuery(navigate, location, queryClient);

  const logoutMutation = useMutation({
    mutationFn: async () => {
      await logout();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
      navigate(Pages.home + getSharedQueryParamsString(location, true));
    },
  });

  const onLogoutClick = () => {
    logoutMutation.mutate();
  };

  if (currentUser === null) {
    return <Unauthorized />;
  }

  return (
    <Box
      sx={{
        width: "24rem",
        color: BPColors.PrimaryMain,
        ...mobileScreenSx({
          width: "100%",
        }),
      }}
    >
      <Typography component="h1" variant="h3">
        Account settings
      </Typography>
      <Box sx={{ marginTop: "2rem" }}>
        <AccountSettingsField
          customSx={{
            "&:hover": {},
          }}
          label="Username"
          text={currentUser.username}
        />
        <AccountSettingsField
          label={getEmailLabelText(currentUser.emailConfirmed)}
          text={currentUser.email}
          showIcon={!currentUser.emailConfirmed}
          icon={<WarningIcon />}
          onEditClicked={() => setEditScreen(AccountScreenEnum.EmailEdit)}
          customSx={{
            marginY: "1.5rem",
            justifyContent: "flex-start",
            "#account-field-icon": {
              marginRight: "0.5rem",
              marginY: "auto",
            },
            "#account-field-edit-icon": {
              marginLeft: "auto",
            },
          }}
        />
        <AccountSettingsField
          label="Change password"
          text="••••••••••"
          onEditClicked={() => setEditScreen(AccountScreenEnum.PasswordEdit)}
        />
        <AccountSettingsField
          label="Edit name"
          text={`${currentUser.firstName} ${currentUser.lastName}`}
          onEditClicked={() => setEditScreen(AccountScreenEnum.NameEdit)}
          customSx={{
            marginY: "1.5rem",
          }}
        />
      </Box>
      <Box
        sx={{
          paddingX: "1rem",
          paddingY: "0.75rem",
          border: "0.063rem solid #E8EBF1",
          borderRadius: "0.25rem",
          display: "flex",
          justifyContent: "space-between",
          minHeight: "3.75rem",
          cursor: "pointer",
          "&:hover": {
            bgcolor: BPColors.SecondaryMain,
          },
        }}
        onClick={onLogoutClick}
      >
        <Box
          id="logout-button"
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "80%",
          }}
        >
          <Box id="logout-text" sx={{ marginY: "auto" }}>
            <Typography
              sx={{
                color: "inherit",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              variant="body1"
            >
              Log out everywhere
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: "0.5rem" }}>
        <Typography
          sx={{ color: "#696B70", fontSize: "0.75rem", fontStyle: "normal" }}
        >
          This will log you out of all Beatport Group products within 10 minutes
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountInfo;
