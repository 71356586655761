import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { BaseTheme } from "./bp-ui/BaseTheme";
import { withAuth } from "./components/hoc/withAuth";
import { withoutAuth } from "./components/hoc/withoutAuth";
import AppConfig from "./components/shared/AppConfig";
import EditPage from "./pages/Edit.page";
import ForgotPasswordPage from "./pages/ForgotPassword.page";
import LoginPage from "./pages/Login.page";
import NotFoundPage from "./pages/NotFound.page";
import RegistrationPage from "./pages/Registration.page";
import ResetPasswordPage from "./pages/ResetPassword.page";
import SettingsPage from "./pages/Settings.page";
import SwaggerPage from "./pages/SwaggerPage";
import ThirdPartyAuthorizationPage from "./pages/ThirdPartyAuthorization.page";
import VerifyEmailPage from "./pages/VerifyEmail.page";
import Pages from "./util/pages";

const queryClient = new QueryClient();

function App() {
  return (
    <BaseTheme>
      <AppConfig>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              <Route path={Pages.home} Component={withoutAuth(LoginPage)} />
              <Route
                path={Pages.register}
                Component={withoutAuth(RegistrationPage)}
              />
              <Route
                path={Pages.forgotPassword}
                Component={withoutAuth(ForgotPasswordPage)}
              />
              <Route path={Pages.resetPassword} Component={ResetPasswordPage} />
              <Route path={Pages.verifyEmail} Component={VerifyEmailPage} />
              <Route path={Pages.settings} Component={withAuth(SettingsPage)} />
              <Route path={Pages.edit} Component={withAuth(EditPage)} />
              <Route path="*" Component={NotFoundPage} />
              <Route path={Pages.docs} Component={SwaggerPage} />
              <Route
                path={Pages.thirdPartyAuthorization}
                Component={withAuth(ThirdPartyAuthorizationPage)}
              />
            </Routes>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppConfig>
    </BaseTheme>
  );
}

export default App;
