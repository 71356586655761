import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useCurrentUserQuery } from "../../queries/useCurrentUser";
import Pages from "../../util/pages";
import {
  getAuthorizeEndpointUrl,
  getSharedQueryParamsString,
  isAuthorizeUrl,
  navigateToLocation,
} from "../util/url";

export function withoutAuth<T extends React.JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>
) {
  const AccountComponent: React.FC<T> = (props: T) => {
    const currentUserQuery = useCurrentUserQuery();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (
        !currentUserQuery.isPending &&
        !currentUserQuery.isFetching &&
        !currentUserQuery.isError
      ) {
        const authUrl = getAuthorizeEndpointUrl(location);
        if (authUrl !== null) {
          if (isAuthorizeUrl(authUrl)) {
            navigateToLocation(authUrl);
          } else {
            navigate(authUrl + getSharedQueryParamsString(location));
          }
        } else {
          navigate(Pages.settings + getSharedQueryParamsString(location));
        }
      }
    }, [currentUserQuery, location, navigate]);

    if (currentUserQuery.isPending && currentUserQuery.isFetching) {
      return <></>;
    }

    return <Component {...props} />;
  };

  return AccountComponent;
}
