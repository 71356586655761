import React, { ReactNode } from "react";

import { ThemeProvider, createTheme } from "@mui/material";

import BPColors from "./Colors";

const theme = createTheme({
  palette: {
    primary: {
      main: BPColors.PrimaryMain,
      light: BPColors.PrimaryLight,
    },
    secondary: {
      main: BPColors.SecondaryMain,
      light: BPColors.SecondaryLight,
    },
  },
  typography: {
    fontFamily: "Aeonik, sans-serif",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: BPColors.PrimaryMain,
          "&.MuiTypography-h3": {
            fontSize: "2.5rem",
            fontStyle: "normal",
            fontWeight: "700",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ".MuiFilledInput-input": {
            height: "1.188rem",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "none",
          },
          "&.MuiFilledInput-root": {
            paddingRight: "1.5rem",
            borderRadius: "0.25rem",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          fontStyle: "normal",
          textDecorationLine: "underline",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          background: BPColors.SecondaryMain,
          color: BPColors.PrimaryMain,
          borderRadius: "0.25rem",
          "&:hover": {
            background: BPColors.SecondaryMain,
            "& .MuiFormLabel-colorPrimary": {
              fontStyle: "normal",
            },
          },
          "& .MuiFilledInput-root:before": {
            borderBottom: "none",
          },
          "& .MuiFilledInput-root:after": {
            borderBottom: `0.125rem solid primary`,
            borderBottomLeftRadius: "0.5rem",
            borderBottomRightRadius: "0.5rem",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: BPColors.PrimaryLight,
            fontStyle: "normal",
          },
          "& .Mui-error": {
            color: BPColors.Error,
          },
          "& .MuiFormLabel-colorPrimary": {
            fontStyle: "italic",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: "white",
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          paddingTop: "0.5rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "2.25rem",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: BPColors.ButtonHover,
          },
          "&.Mui-disabled": {
            backgroundColor: BPColors.ButtonDisabled,
            color: BPColors.PrimaryLight,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: "white",
          borderRadius: "0.5rem",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          marginRight: "-1.125rem",
        },
      },
    },
  },
});

interface OwnProps {
  children: ReactNode;
}

export const BaseTheme = ({ children }: OwnProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
