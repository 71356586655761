import React from "react";

import AccountSettings from "../components/settings/AccountSettings";
import FullPageLayout from "../components/shared/FullPageLayout";

const SettingsPage = () => {
  return (
    <FullPageLayout>
      <AccountSettings />
    </FullPageLayout>
  );
};

export default SettingsPage;
