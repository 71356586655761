import React, { useCallback, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { AxiosError, HttpStatusCode } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { register } from "../api/auth";
import Box from "../bp-ui/components/Box";
import Link from "../bp-ui/components/Link";
import AccountCreation from "../components/registration/AccountCreation";
import PasswordCreation from "../components/registration/PasswordCreation";
import ProfileCreation from "../components/registration/ProfileCreation";
import SignUp from "../components/registration/SignUp";
import {
  FormState,
  getClientIdParamValue,
  initialFormState,
} from "../components/registration/util";
import { BrandLogo } from "../components/shared/BrandLogo";
import ErrorAlert from "../components/shared/ErrorAlert";
import PageCard from "../components/shared/PageCard";
import {
  getAuthorizeEndpointUrl,
  navigateToLocation,
} from "../components/util/url";
import { RegisterFormData } from "../models/auth";
import { FormField } from "../models/field";
import Pages from "../util/pages";

const RegistrationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState(0);

  const [formState, setFormState] = useState<FormState>(initialFormState);

  const [showError, setShowError] = useState(false);

  const updateField = useCallback(
    (fieldName: keyof FormState, fieldValue: FormField<string>) => {
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: fieldValue,
      }));
    },
    [setFormState]
  );

  const createSetter = useCallback(
    (fieldName: keyof FormState) => {
      return (fieldValue: FormField<string>) => {
        updateField(fieldName, fieldValue);
      };
    },
    [updateField]
  );

  const incrementStep = () => setCurrentStep((prev) => prev + 1);

  const decrementStep = () => {
    setCurrentStep((prev) => prev - 1), setShowError(false);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: RegisterFormData) => {
      await register(data);
    },

    onSuccess: () => {
      const authUrl = getAuthorizeEndpointUrl(location);
      if (authUrl !== null) {
        navigateToLocation(authUrl);
      } else {
        navigate(Pages.home, { state: { registrationSuccessful: true } });
      }
    },
    onError: (error: AxiosError) => {
      switch (error.response?.status) {
        case HttpStatusCode.Unauthorized:
          setShowError(true);
          break;
        default:
          setShowError(false);
          break;
      }
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    mutate({
      username: formState.username.value,
      password: formState.password.value,
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      email: formState.email.value,
      cfTurnstileResponse: formState.turnstileToken.value,
      appClientId: getClientIdParamValue(location),
    });
  };

  return (
    <PageCard>
      <BrandLogo />
      <Box id="registration-form" component="form" onSubmit={onSubmit}>
        {showError && (
          <ErrorAlert
            customSx={{
              marginY: "1.5rem",
              lineHeight: "1rem",
            }}
            message={
              <>
                Unable to register account, please try again. If issue persists
                please{" "}
                <Link
                  color="inherit"
                  href="https://support.beatport.com/hc/en-us/requests/new"
                  target="_blank"
                >
                  contact support.
                </Link>
              </>
            }
          />
        )}
        {currentStep === 0 && (
          <SignUp
            formState={formState}
            createSetter={createSetter}
            incrementStep={incrementStep}
          />
        )}
        {currentStep === 1 && (
          <PasswordCreation
            formState={formState}
            createSetter={createSetter}
            incrementStep={incrementStep}
            decrementStep={decrementStep}
          />
        )}
        {currentStep === 2 && (
          <ProfileCreation
            formState={formState}
            createSetter={createSetter}
            incrementStep={incrementStep}
            decrementStep={decrementStep}
          />
        )}
        {currentStep === 3 && (
          <AccountCreation
            formState={formState}
            createSetter={createSetter}
            userEmail={formState.email.value}
            decrementStep={decrementStep}
            isLoading={isPending}
          />
        )}
      </Box>
    </PageCard>
  );
};

export default RegistrationPage;
