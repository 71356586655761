import React, { useRef } from "react";

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import SignInText from "./SignInText";
import { CreateSetterFunction, FormState } from "./util";
import { is417ClientError, isHttpClientError } from "../../api/httpClient";
import { validateCredentials } from "../../api/validation";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import {
  EMAIL_ALREADY_EXISTS_TRY_LOGIN_ERROR_MSG,
  EMAIL_NOT_VALID_ERROR_MSG,
  USERNAME_ALREADY_EXISTS_ERROR_MSG,
  USERNAME_NOT_VALID_ERROR_MSG,
} from "../../util/errorMessages";
import EmailField from "../account/EmailField";
import UsernameField from "../account/UsernameField";
import { BrandFooter } from "../shared/BrandFooter";
import PrimaryButton from "../shared/PrimaryButton";
import {
  basicEmailValidation,
  basicUsernameValidation,
} from "../util/account-fields-validation";

interface OwnProps {
  formState: FormState;
  createSetter: CreateSetterFunction;
  incrementStep: () => void;
}

const SignUp = ({ formState, createSetter, incrementStep }: OwnProps) => {
  const setUsername = (value: string, error: boolean, errorMessage: string) =>
    createSetter("username")({ value, error, errorMessage });
  const setEmail = (value: string, error: boolean, errorMessage: string) =>
    createSetter("email")({ value, error, errorMessage });

  const usernameValidationInProgressRef = useRef(false);
  const emailValidationInProgressRef = useRef(false);

  const {
    mutate: mutateValidateUsername,
    isPending: isValidateUsernameLoading,
  } = useMutation({
    mutationFn: async (value: string) => {
      usernameValidationInProgressRef.current = true;

      const isValid = basicUsernameValidation(value);

      if (!isValid) {
        throw new Error("Invalid username.");
      }

      await validateCredentials("username", value);
    },

    onSettled: (_data, error) => {
      if (error) {
        let errorMessage = USERNAME_NOT_VALID_ERROR_MSG;

        if (isHttpClientError(error)) {
          errorMessage = USERNAME_ALREADY_EXISTS_ERROR_MSG;
        }

        setUsername(formState.username.value, true, errorMessage);
        return;
      }
      usernameValidationInProgressRef.current = false;
      handleIncrementStep();
    },
  });

  const { mutate: mutateValidateEmail, isPending: isValidateEmailLoading } =
    useMutation({
      mutationFn: async (value: string) => {
        emailValidationInProgressRef.current = true;
        const isValid = basicEmailValidation(value);

        if (!isValid) {
          throw new Error("Invalid email.");
        }

        await validateCredentials("email", value);
      },
      onSettled(_data, error) {
        if (error) {
          let errorMessage = EMAIL_NOT_VALID_ERROR_MSG;

          if (
            isHttpClientError(error) &&
            is417ClientError(error as AxiosError)
          ) {
            errorMessage = EMAIL_ALREADY_EXISTS_TRY_LOGIN_ERROR_MSG;
          }
          setEmail(formState.email.value, true, errorMessage);
          return;
        }
        emailValidationInProgressRef.current = false;
        handleIncrementStep();
      },
    });

  const handleIncrementStep = () => {
    if (
      usernameValidationInProgressRef.current === true ||
      emailValidationInProgressRef.current === true
    ) {
      return;
    }
    if (formState.email.error || formState.username.error) {
      return;
    }

    incrementStep();
  };

  const handleSetUsername = (value: string) => {
    setUsername(value, false, "");
  };

  const handleSetEmail = (value: string) => {
    setEmail(value, false, "");
  };

  const onContinueClick = async () => {
    await mutateValidateUsername(formState.username.value);
    await mutateValidateEmail(formState.email.value);
  };

  const isLoading = () => {
    return isValidateEmailLoading || isValidateUsernameLoading;
  };

  return (
    <Box className="fade-in">
      <Typography component="h1" variant="h3">
        Sign up
      </Typography>

      <SignInText />
      <Box
        sx={{
          marginY: "2rem",
        }}
      >
        <UsernameField
          value={formState.username.value}
          onChange={handleSetUsername}
          error={formState.username.error}
          helperText={formState.username.errorMessage}
        />
        <EmailField
          value={formState.email.value}
          error={formState.email.error}
          helperText={formState.email.errorMessage}
          onChange={handleSetEmail}
          sx={{ marginY: "1.5rem" }}
        />
        <PrimaryButton
          type="button"
          label="Continue"
          onClick={onContinueClick}
          isLoading={isLoading()}
        />
      </Box>
      <BrandFooter />
    </Box>
  );
};

export default SignUp;
