export default class Pages {
  static readonly home = "/";
  static readonly register = "/register";
  static readonly forgotPassword = "/forgot-password";
  static readonly resetPassword = "/reset-password";
  static readonly verifyEmail = "/verify-email";
  static readonly settings = "/settings";
  static readonly edit = "/edit/:userId";
  static readonly docs = "/docs";
  static readonly thirdPartyAuthorization = "/third-party-authorization";
}
