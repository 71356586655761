import React, { useCallback } from "react";

import RegistrationFormStep from "./RegistrationFormStep";
import { CreateSetterFunction, FormState } from "./util";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { PasswordField } from "../account/PasswordField";
import PrimaryButton from "../shared/PrimaryButton";
import { basicPasswordValidation } from "../util/account-fields-validation";

interface OwnProps {
  formState: FormState;
  createSetter: CreateSetterFunction;
  incrementStep: () => void;
  decrementStep: () => void;
}

const passwordRequirementsMsg = "Must be at least 8 characters long";

const PasswordCreation = ({
  formState,
  createSetter,
  incrementStep,
  decrementStep,
}: OwnProps) => {
  const setPassword = useCallback(
    (value: string, error: boolean, errorMessage: string) => {
      createSetter("password")({ value, error, errorMessage });
    },
    [createSetter]
  );

  const handleSetPassword = (value: string) => {
    if (value.length === 0) {
      setPassword(value, true, passwordRequirementsMsg);
      return;
    }

    setPassword(value, false, formState.password.errorMessage);
  };

  const onContinueClick = () => {
    const isValid = basicPasswordValidation(formState.password.value);

    if (!isValid) {
      setPassword(formState.password.value, true, passwordRequirementsMsg);
      return;
    }

    incrementStep();
  };

  return (
    <Box className="fade-in">
      <RegistrationFormStep
        currentStep="1"
        numberOfSteps="3"
        decrementStep={decrementStep}
      />
      <Typography component="h1" variant="h3">
        Create a password
      </Typography>
      <Box
        sx={{
          marginTop: "0.5rem",
        }}
      >
        <PasswordField
          id="password"
          label="Password"
          customSx={{ marginY: "1.5rem" }}
          value={formState.password.value}
          onChange={handleSetPassword}
          error={formState.password.error}
          helperText={passwordRequirementsMsg}
        />
        <PrimaryButton
          type="button"
          label="Continue"
          onClick={onContinueClick}
        />
      </Box>
    </Box>
  );
};

export default PasswordCreation;
