import React from "react";

import { useCurrentUserQuery } from "../../queries/useCurrentUser";
import Unauthorized from "../shared/Unauthorized";

export function withAuth<T extends React.JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>
) {
  const AccountComponent: React.FC<T> = (props: T) => {
    const currentUserQuery = useCurrentUserQuery();

    if (
      !currentUserQuery.isPending &&
      !currentUserQuery.isFetching &&
      !currentUserQuery.data
    ) {
      return <Unauthorized />;
    }

    return <Component {...props} />;
  };

  return AccountComponent;
}
