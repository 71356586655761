import React from "react";

import RegistrationFormStep from "./RegistrationFormStep";
import { CreateSetterFunction, FormState } from "./util";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { NAME_NOT_VALID_ERROR_MSG } from "../../util/errorMessages";
import PrimaryButton from "../shared/PrimaryButton";
import TextField from "../shared/TextField";
import { basicNameValidation } from "../util/account-fields-validation";

interface OwnProps {
  formState: FormState;
  createSetter: CreateSetterFunction;
  incrementStep: () => void;
  decrementStep: () => void;
}

const ProfileCreation = ({
  formState,
  createSetter,
  incrementStep,
  decrementStep,
}: OwnProps) => {
  const setFirstName = (
    value: string,
    error: boolean,
    errorMessage: string
  ) => {
    createSetter("firstName")({
      value,
      error,
      errorMessage,
    });
  };

  const setLastName = (value: string, error: boolean, errorMessage: string) => {
    createSetter("lastName")({
      value,
      error,
      errorMessage,
    });
  };

  const handleSetFirstName = (value: string) => {
    setFirstName(value, false, "");
  };

  const handleSetLastName = (value: string) => {
    setLastName(value, false, "");
  };

  const onContinueClick = () => {
    const isFirstNameValid = basicNameValidation(formState.firstName.value);
    const isLastNameValid = basicNameValidation(formState.lastName.value);

    if (!isFirstNameValid) {
      setFirstName(formState.firstName.value, true, NAME_NOT_VALID_ERROR_MSG);
    }

    if (!isLastNameValid) {
      setLastName(formState.lastName.value, true, NAME_NOT_VALID_ERROR_MSG);
    }

    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      formState.firstName.error ||
      formState.lastName.error
    ) {
      return;
    }

    incrementStep();
  };

  return (
    <Box className="fade-in">
      <RegistrationFormStep
        decrementStep={decrementStep}
        currentStep="2"
        numberOfSteps="3"
      />
      <Typography component="h1" variant="h3">
        What&apos;s your name?
      </Typography>
      <Box
        sx={{
          marginTop: "0.5rem",
        }}
      >
        <TextField
          id="fName"
          label="First name"
          value={formState.firstName.value}
          onChange={handleSetFirstName}
          customSx={{ marginY: "1.5rem" }}
          error={formState.firstName.error}
          helperText={formState.firstName.errorMessage}
          inputProps={{ maxLength: 150 }}
        />
        <TextField
          id="lName"
          label="Last name"
          value={formState.lastName.value}
          onChange={handleSetLastName}
          customSx={{ marginBottom: "1.5rem" }}
          error={formState.lastName.error}
          helperText={formState.lastName.errorMessage}
          inputProps={{ maxLength: 150 }}
        />
        <PrimaryButton
          type="button"
          label="Continue"
          onClick={onContinueClick}
        />
      </Box>
    </Box>
  );
};

export default ProfileCreation;
