import React from "react";

import ErrorAlert from "./ErrorAlert";
import Link from "../../bp-ui/components/Link";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  customSx?: CustomSx;
}

const ClosedAccountErrorAlert = ({ customSx }: OwnProps) => {
  return (
    <ErrorAlert
      customSx={{ ...customSx }}
      message={
        <>
          This account has been closed, please reach out to{" "}
          <Link
            color="inherit"
            href="https://support.beatport.com/hc/en-us/requests/new"
            target="_blank" // new tab or no?
          >
            customer service
          </Link>{" "}
          to reopen your account.
        </>
      }
    />
  );
};

export default ClosedAccountErrorAlert;
