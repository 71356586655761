import React, { useState } from "react";

import AccountScreenEnum from "./account-screen-enum";
import AccountInfo from "./AccountInfo";
import EmailEdit from "./EmailEdit";
import EmailVerify from "./EmailVerify";
import PasswordEdit from "./PasswordEdit";
import ProfileEdit from "./ProfileEdit";
import Box from "../../bp-ui/components/Box";
import { mobileScreenSx } from "../util/screen";

const AccountSettings = () => {
  const [screen, setScreen] = useState<AccountScreenEnum>(
    AccountScreenEnum.Preview
  );

  return (
    <Box
      sx={{
        ...mobileScreenSx({
          paddingTop: "2rem",
          width: "100%",
        }),
      }}
    >
      {screen === AccountScreenEnum.Preview && (
        <AccountInfo setEditScreen={setScreen} />
      )}
      {screen === AccountScreenEnum.EmailEdit && (
        <EmailEdit setEditScreen={setScreen} />
      )}
      {screen === AccountScreenEnum.EmailVerify && (
        <EmailVerify setEditScreen={setScreen} />
      )}
      {screen === AccountScreenEnum.PasswordEdit && (
        <PasswordEdit setEditScreen={setScreen} />
      )}
      {screen === AccountScreenEnum.NameEdit && (
        <ProfileEdit setEditScreen={setScreen} />
      )}
    </Box>
  );
};

export default AccountSettings;
